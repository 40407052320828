@font-face {
  font-family: "title-en";
  src: url("../../../fonts/LemonMilk.woff2") format("woff2"), url("../../../fonts/LemonMilk.woff") format("woff"), url("../../../fonts/LemonMilk.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "text-en";
  src: url("../../../fonts/times-new-roman.woff2") format("woff2"), url("../../../fonts/times-new-roman.woff") format("woff"), url("../../../fonts/times-new-roman.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "title-kh";
  src: url("../../../fonts/Hanuman.woff2") format("woff2"), url("../../../fonts/Hanuman.woff") format("woff"), url("../../../fonts/Hanuman.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "text-kh";
  src: url("../../../fonts/Hanuman.woff2") format("woff2"), url("../../../fonts/Hanuman.woff") format("woff"), url("../../../fonts/Hanuman.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.slide {
  width: 100%;
  padding: 0;
}

.carousel-control-prev,
.carousel-control-next {
  width: 5%;
}

.carousel-item .container-slide {
  height: 100%;
  position: absolute;
  width: 70%;
  top: 0;
  left: 5%;
}

.carousel-item .container-slide .slide-caption {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  padding: 0 15px;
  transform: translateY(-50%);
}

.carousel-item .container-slide .slide-caption h2.title-slide {
  font-size: 40px;
  position: relative;
  left: -10%;
  transition-duration: 1s;
  color: #ffffff;
  text-shadow: 1px 1px 6px #6e6e6ea1;
}

.carousel-item .container-slide .slide-caption h2.title-slide strong {
  color: #153158;
}

.carousel-item .container-slide .slide-caption .slide-text p.text-body {
  font-size: 20px;
  position: relative;
  left: -9%;
  transition-duration: 1s;
}

.carousel-item .container-slide .slide-caption .btn-slide {
  display: inline-block;
  background-color: #0c2041;
  padding: 10px 20px;
  align-items: center;
  text-transform: uppercase;
  border-radius: 4px;
  position: relative;
  transition-duration: 1s;
  left: -8%;
}

.carousel-item .container-slide .slide-caption .btn-slide a {
  color: white;
}

.carousel-item.active .slide-caption {
  transition-duration: 0.5s;
}

.carousel-item.active .slide-caption h2.title-slide {
  position: relative;
  left: 0;
}

.carousel-item.active .slide-caption .slide-text p.text-body {
  position: relative;
  left: 0;
}

.carousel-item.active .slide-caption .btn-slide {
  position: relative;
  left: 0;
}

.carousel-item.active .slide-caption .btn-slide a {
  color: white;
}

.carousel-indicators li {
  background-color: #153158;
}

#about .head h1.title {
  color: #153158;
}

#about .head h1.titlespan {
  color: #cf9455 !important;
}

#about .list-point {
  padding-bottom: 20px;
}

#about .list-point .point i {
  font-size: 18px;
  padding-right: 20px;
  color: #cf9455;
}

#services {
  background-color: #dadada30;
}

#services .row.list-service {
  margin-right: -30px;
  margin-left: -30px;
}

#services .row.list-service .col-4 {
  padding: 30px;
}

#services .service {
  background-color: white;
  padding: 40px 15px;
  transition: all 0.3s ease-out 0s;
}

#services .service .image {
  padding-bottom: 15px;
}

#services .service .image img {
  height: 80px;
}

#services .service h4.name {
  font-size: 16px;
  height: 37px;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

#services .service .des {
  margin: 10px 0;
}

#services .service .content-text p {
  font-size: 12px;
}

#services .service .btn-service a {
  display: inline-block;
  text-transform: uppercase;
  color: #cf9455;
  font-size: 12px;
  font-weight: 600;
  border: 2px solid #cf9455;
  padding: 5px 20px;
  transition-duration: 0.5s;
}

#services .service .btn-service a:hover {
  background-color: #cf9455;
  color: white;
}

#services .service::after {
  box-shadow: 0px 1px 5px 2px #a6a6a6;
}

section.quote {
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

section.quote .text-quote h4 {
  color: white;
  padding: 6% 25%;
  margin: 0 auto;
  text-align: center;
  font-size: 60px;
}

section.quote .text-quote h4 span {
  color: #cf9455;
}

#notaries .modal-body .image img {
  height: auto;
}

#notaries .image {
  overflow: hidden;
}

#notaries h4.name {
  margin: 10px 0;
  font-size: 18px;
}

#notaries .pos {
  color: #cf9455;
}

#notaries .social {
  margin-top: 10px;
}

#notaries .social ul li a.nav-link {
  height: 40px;
  width: 40px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #dadadac2;
}

#notaries .social ul li a.nav-link i {
  font-size: 20px;
}

#notaries .content-center .content-text p {
  margin: 0;
}

#testimonials {
  background-color: #eaeaea;
}

#testimonials .list-testimonial .testimonial {
  padding: 30px 15px;
  background-color: #f5f5f5;
}

#testimonials .list-testimonial .testimonial h4.name {
  font-size: 18px;
}

#testimonials .list-testimonial .testimonial .image {
  height: 200px;
  width: 200px;
  border-radius: 50%;
  border: 1px solid #f5f5f5;
  position: relative;
  overflow: hidden;
  margin: 10px auto;
}

#testimonials .btn-box {
  margin-top: 30px;
}

#testimonial-form .modal-body {
  text-align: left;
}

#testimonial-form .modal-body .profile {
  text-align: center;
}

#testimonial-form .modal-body .profile img {
  height: 100px;
}

#testimonial-form .modal-body button.btn {
  background-color: #153158;
  display: flex;
  justify-content: center;
  width: 30%;
  align-items: center;
  margin: 0 auto;
  color: white;
}

#blog .blog .image {
  position: relative;
  overflow: hidden;
}

#blog .blog .image .date {
  position: absolute;
  right: 15px;
  bottom: 15px;
  padding: 5px 15px;
  border-radius: 30px;
  background-color: #cf9455;
  font-size: 16px;
  color: white;
}

#blog .blog h4.name {
  margin: 10px 0;
  font-size: 20px;
}

#careers h2.title {
  color: white;
}

#careers .head-text {
  color: white;
}

#careers .career {
  padding: 15px;
  background-color: #f5f5f5e0;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

#careers .career .position {
  font-weight: 600;
}

#careers .career .title h3 {
  font-size: 18px;
}

#careers .career .address {
  display: inline-block;
}

#careers .career .salary,
#careers .career .view-more {
  display: inline-block;
  background-color: #153158c9;
  color: white;
  padding: 5px;
  border-radius: 4px;
}

#careers .career .end-date {
  display: inline-block;
  background-color: white;
  padding: 5px;
  border-radius: 4px;
}

#contact button.btn {
  background-color: #cf9455;
  border: none;
  padding: 10px 20px;
  text-transform: uppercase;
}

#contact .contact-from {
  background: #ffffff;
  padding: 30px 5%;
  border-radius: 7px;
  box-shadow: 0 5px 5px 0px rgba(0, 0, 0, 0.16);
}

#contact .map {
  border: 10px solid #f7f7f7;
}

#contact .map iframe {
  width: 100%;
}

.swiper-container {
  width: 100%;
  height: 100% !important;
}

.swiper-slide {
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

@media (max-width: 768px) {
  .swiper-slide {
    height: 100% !important;
  }

  .swiper-button-next {
    right: 20px;
    transform: rotate(90deg);
    display: none;
  }

  .swiper-button-prev {
    left: 20px;
    transform: rotate(90deg);
    display: none;
  }
}

div#press-detail,
#qrcode {
  width: 70%;
  min-height: 70vh;
}

div#press-detail h1,
#qrcode h1 {
  font-size: 26px;
  margin: 10px 0;
}

div#press-detail table,
#qrcode table {
  border: 2px solid #083791;
}

#job-detail {
  min-height: 90vh;
  width: 70%;
}

#job-detail h1 {
  font-size: 26px;
  margin: 10px 0;
}

#job-detail .info {
  background-color: #f5f5f5;
  padding: 15px;
  border-bottom: 2px solid #153158;
}

#job-detail .info i {
  height: 20px;
  width: 20px;
  padding-right: 15px;
  font-size: 14px;
}

#job-detail .info .salary,
#job-detail .info .position,
#job-detail .info .time-work {
  font-size: 20px;
}

#job-detail .text-content h4 {
  border-top: 3px solid;
  padding: 15px 0;
  margin-bottom: 15px;
}

html:lang(kh) #services .service h4.name {
  font-size: 20px;
}

html:lang(kh) #services .service .des {
  margin: 10px 0;
  line-height: 24px;
}

html:lang(kh) #blog .blog h4.name {
  line-height: 34px;
}

html:lang(kh) #blog .blog .des {
  height: 77px;
  line-height: 26px;
}

@media (max-width: 992px) {
  .carousel-item .container-slide {
    width: 100%;
    left: 0;
  }

  .carousel-item .container-slide .slide-caption {
    width: 90%;
    margin: 0 auto;
    text-align: center;
  }

  .carousel-item .container-slide .slide-caption h2.title-slide {
    font-size: 30px;
  }

  #job-detail .info {
    background-color: transparent;
    padding: 0;
    border-bottom: none;
  }

  #job-detail .info i {
    height: 20px;
    width: 20px;
    padding-right: 15px;
    font-size: 14px;
  }

  #job-detail .info .salary,
  #job-detail .info .position,
  #job-detail .info .time-work {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .carousel-item .container-slide .slide-caption .btn-slide {
    font-size: 12px;
    padding: 5px 10px;
  }

  #about .head h1.title {
    font-size: 30px;
  }

  #services .service {
    background-color: white;
    padding: 20px 10px;
    transition: all 0.3s ease-out 0s;
  }

  #contact .contact-from {
    margin-bottom: 15px;
  }

  #blog .blog .image .date {
    padding: 5px 10px;
    font-size: 12px;
  }

  .carousel-indicators {
    display: none;
  }

  div#press-detail,
  #qrcode {
    width: 95%;
  }

  #job-detail {
    width: 95%;
  }

  html:lang(kh) .carousel-item .container-slide .slide-caption h2.title-slide {
    font-size: 20px;
    line-height: 28px;
  }
}

@media (max-width: 568px) {
  #careers .career .view-more {
    margin-top: 10px;
  }

  #services .modal-header {
    padding: 0;
  }

  #services .modal-header .close {
    z-index: 99999;
  }

  #services .modal-body h4 {
    font-size: 16px;
    margin-top: 10px;
  }

  #services .modal-body .image img {
    height: 60px;
  }

  #notaries .modal-body .image img {
    height: auto;
  }

  .modal .modal-dialog.display {
    width: 95%;
    margin: 0 auto;
  }
}

